function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread2(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
function _defineProperty(obj, key, value) {
  key = _toPropertyKey(key);
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;
  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }
  return target;
}
function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};
  var target = _objectWithoutPropertiesLoose(source, excluded);
  var key, i;
  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }
  return target;
}
function _toPrimitive(input, hint) {
  if (typeof input !== "object" || input === null) return input;
  var prim = input[Symbol.toPrimitive];
  if (prim !== undefined) {
    var res = prim.call(input, hint || "default");
    if (typeof res !== "object") return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (hint === "string" ? String : Number)(input);
}
function _toPropertyKey(arg) {
  var key = _toPrimitive(arg, "string");
  return typeof key === "symbol" ? key : String(key);
}

var _excluded = ["width", "height", "viewBox"],
  _excluded2 = ["tabindex"];
/**
 * Copyright IBM Corp. 2018, 2024
 *
 * This source code is licensed under the Apache-2.0 license found in the
 * LICENSE file in the root directory of this source tree.
 */

var defaultAttributes = {
  // Reference:
  // https://github.com/IBM/carbon-components-react/issues/1392
  // https://github.com/PolymerElements/iron-iconset-svg/pull/47
  // `focusable` is a string attribute which is why we do not use a boolean here
  focusable: 'false',
  preserveAspectRatio: 'xMidYMid meet'
};

/**
 * Get supplementary HTML attributes for a given <svg> element based on existing
 * attributes.
 */
function getAttributes() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    width = _ref.width,
    height = _ref.height,
    _ref$viewBox = _ref.viewBox,
    viewBox = _ref$viewBox === void 0 ? "0 0 ".concat(width, " ").concat(height) : _ref$viewBox,
    attributes = _objectWithoutProperties(_ref, _excluded);
  var tabindex = attributes.tabindex,
    rest = _objectWithoutProperties(attributes, _excluded2);
  var iconAttributes = _objectSpread2(_objectSpread2(_objectSpread2({}, defaultAttributes), rest), {}, {
    width: width,
    height: height,
    viewBox: viewBox
  });

  // TODO: attributes.title assumes that the consumer will implement <title> and
  // correctly set `aria-labelledby`.
  if (iconAttributes['aria-label'] || iconAttributes['aria-labelledby'] || iconAttributes.title) {
    iconAttributes.role = 'img';

    // Reference:
    // https://allyjs.io/tutorials/focusing-in-svg.html
    if (tabindex !== undefined && tabindex !== null) {
      iconAttributes.focusable = 'true';
      iconAttributes.tabindex = tabindex;
    }
  } else {
    iconAttributes['aria-hidden'] = true;
  }
  return iconAttributes;
}

/**
 * Copyright IBM Corp. 2018, 2024
 *
 * This source code is licensed under the Apache-2.0 license found in the
 * LICENSE file in the root directory of this source tree.
 */
/**
 * Convert an icon descriptor to a String
 */
function toString(descriptor) {
  var _descriptor$elem = descriptor.elem,
    elem = _descriptor$elem === void 0 ? 'svg' : _descriptor$elem,
    _descriptor$attrs = descriptor.attrs,
    attrs = _descriptor$attrs === void 0 ? {} : _descriptor$attrs,
    _descriptor$content = descriptor.content,
    content = _descriptor$content === void 0 ? [] : _descriptor$content;
  var children = content.map(toString).join('');
  if (elem !== 'svg') {
    return "<".concat(elem, " ").concat(formatAttributes(attrs), ">").concat(children, "</").concat(elem, ">");
  }
  return "<".concat(elem, " ").concat(formatAttributes(getAttributes(attrs)), ">").concat(children, "</").concat(elem, ">");
}
function formatAttributes(attrs) {
  return Object.keys(attrs).reduce(function (acc, key, index) {
    var attribute = "".concat(key, "=\"").concat(attrs[key], "\"");
    if (index === 0) {
      return attribute;
    }
    return acc + ' ' + attribute;
  }, '');
}

/**
 * Copyright IBM Corp. 2018, 2024
 *
 * This source code is licensed under the Apache-2.0 license found in the
 * LICENSE file in the root directory of this source tree.
 */
/**
 * Convert an icon descriptor to a DOM node.
 */
function toSVG(descriptor) {
  var _descriptor$elem = descriptor.elem,
    elem = _descriptor$elem === void 0 ? 'svg' : _descriptor$elem,
    _descriptor$attrs = descriptor.attrs,
    attrs = _descriptor$attrs === void 0 ? {} : _descriptor$attrs,
    _descriptor$content = descriptor.content,
    content = _descriptor$content === void 0 ? [] : _descriptor$content;
  var node = document.createElementNS('http://www.w3.org/2000/svg', elem);
  var attributes = elem !== 'svg' ? attrs : getAttributes(attrs);
  Object.keys(attributes).forEach(function (key) {
    node.setAttribute(key, attrs[key]);
  });
  for (var i = 0; i < content.length; i++) {
    node.appendChild(toSVG(content[i]));
  }
  return node;
}

export { defaultAttributes, formatAttributes, getAttributes, toSVG, toString };
